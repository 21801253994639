import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Slate,
  Editable,
  withReact,
  ReactEditor,
  useSlate,
  useFocused,
} from "slate-react";
import { createEditor, Editor, Transforms, Range } from "slate";
import { withHistory } from "slate-history";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import styled from "styled-components";
import { shortTimecode } from "../TimeCodeConverter";
import UpdateSpeaker from "./UpdateSpeaker";
import { secondsToTimecode } from "../TimeCodeConverter/secondsToTimecodeNew";
import { isArray } from "lodash";
import { Tooltip } from "@material-ui/core";
import { Button, Icon, Menu, Portal, CopyButton } from "./function";
import { css } from "@emotion/css";

const Index = ({
  data,
  setData,
  handleWordClick,
  currentTime,
  showTimecodes,
  isEditable,
  showSpeakers,
  speakersData,
  timecodeOffset,
  updateState,
  render,
  setRender,
  captureText,
  name,
  role,
  isPlaying,
  setFilterSpeaker,
  LiveTranscription,
}) => {
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const [value, setValue] = useState([]);
  const [state, setState] = useState({ state: false });
  const [trigger, setTrigger] = useState({});
  const [cursor, setCursor] = useState({});
  // const [referesh, setReferesh] = useState(false);

  const getMediaCurrentTime = secondsToTimecode(currentTime + timecodeOffset);

  useEffect(() => {
    const callback = (event) => {
      if (event.code === "Backquote") {
        navigator.clipboard.writeText(getMediaCurrentTime);
      }
    };
    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  useEffect(() => {
    if (data) {
      const response = splitContent(data, currentTime);
      setValue(response);
      setRender(false);
    }
    if (value) {
      editor.history.undos = [];
      editor.children = value;
      editor.selection = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  useEffect(() => {
    if (value) {
      setData(
        value.map((el) => {
          return {
            id: el?.id,
            end: el?.end,
            confidence: el?.confidence,
            text: el?.children,
            start: el?.start,
            speaker: el?.speaker,
            mediaId: el?.mediaId,
            transcriptionFlag: el?.transcriptionFlag,
            speechFinal: el?.speechFinal,
            utteranceEnd: el?.utteranceEnd,
            lastTranscriptEndTime: el?.lastTranscriptEndTime,
          };
        }),
      );
      if (LiveTranscription) {
        let result = {};
        value.map((item) => {
          return (
            item?.oldSpeaker !== item?.speaker &&
            (result[item?.oldSpeaker] = item?.speaker)
          );
        });
        setFilterSpeaker(result);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function generatePreviousTimingsUpToCurrent(start, end) {
    const startTimeInt = parseInt(start);
    const endTimeInt = parseInt(end);
    return `${startTimeInt} ${endTimeInt}`;
  }

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "timedText":
        return <TimedTextElement {...props} />;
      default:
        return <DefaultElement {...{ props }} />;
    }
  }, []);

  const DefaultElement = (props) => {
    return <p {...props.attributes}>{props.children}</p>;
  };

  const TimedTextElement = (props) => {
    let startTimecode = props.element?.start;
    if (timecodeOffset) {
      startTimecode += timecodeOffset;
    }

    return (
      <React.Fragment>
        <DrawerContainer style={{ display: "flex" }} contentEditable={false}>
          {showSpeakers ? (
            <Container>
              {/* <Person style={{ fill: COLORS.BTN_GREEN, cursor: "pointer" }} /> */}
              <ProfileImage
                src={require("../../../assets/images/userPlaceholder.svg")}
              />
              <ValueSpeaker
                style={{ marginLeft: "5px" }}
                onClick={() =>
                  editor.children[editor.children.length - 1].children[0]
                    .text === props.element.children[0].text
                    ? ""
                    : setState({
                        state: true,
                        element: props.element,
                      })
                }
              >
                {props.element.speaker}
              </ValueSpeaker>
            </Container>
          ) : null}
          {showTimecodes ? (
            <ValueTimesCode
              data-start={props.element.start}
              onClick={() => handleWordClick(startTimecode)}
            >
              {shortTimecode(startTimecode)}
            </ValueTimesCode>
          ) : null}{" "}
          {props.element?.confidence ? (
            <ValueConfidence>{`${props.element?.confidence} %`}</ValueConfidence>
          ) : null}
          <ValueIds>{props.element?.id}</ValueIds>
        </DrawerContainer>
        <CustomTextAreaOtter
          style={{
            color: COLORS.COLOR_DARK,
          }}
          onDoubleClick={() => handleWordClick(startTimecode)}
        >
          {props.children}
        </CustomTextAreaOtter>
      </React.Fragment>
    );
  };

  const generateText = (words) => {
    return words.text;
  };

  const splitContent = (paragraphs) => {
    return paragraphs.map((paragraph, index) => ({
      speaker: paragraph.speaker,
      confidence: paragraph.confidence,
      id: paragraph.id,
      start: paragraph?.start,
      end: paragraph?.end,
      previousTimings: generatePreviousTimingsUpToCurrent(
        paragraph.start,
        paragraph.end,
      ),
      oldSpeaker: paragraph?.oldSpeaker
        ? paragraph?.oldSpeaker
        : paragraph?.speaker,
      mediaId: paragraph?.mediaId,
      transcriptionFlag: paragraph?.transcriptionFlag,
      speechFinal: paragraph?.speechFinal,
      utteranceEnd: paragraph?.utteranceEnd,
      lastTranscriptEndTime: paragraph?.lastTranscriptEndTime,
      // pre-computing the display of the formatting here so that it doesn't need to convert it in leaf render
      startTimecode: paragraph.start,
      type: "timedText",
      children: isArray(paragraph.text)
        ? paragraph.text
        : [
            {
              text: generateText(paragraph),
            },
          ],
      // children: [
      //   {
      //     text: generateText(paragraph),
      //   },
      // ],
    }));
  };

  const handleChange = (leaf, parent) => {
    let data = parent.children.map((item) =>
      item.text === leaf.text ? { text: leaf["suggestionText"] } : item,
    );

    const pathToCurrentNode = ReactEditor.findPath(editor, parent);
    const updateNode = { ...parent, children: data };
    Transforms.removeNodes(editor, { at: pathToCurrentNode });
    Transforms.insertNodes(editor, updateNode, { at: pathToCurrentNode });
  };

  const handleRemove = (leaf, parent) => {
    let data = parent.children.map((item) =>
      item.text === leaf.text ? { text: leaf["text"] } : item,
    );

    const pathToCurrentNode = ReactEditor.findPath(editor, parent);
    const updateNode = { ...parent, children: data };
    Transforms.removeNodes(editor, { at: pathToCurrentNode });
    Transforms.insertNodes(editor, updateNode, { at: pathToCurrentNode });
  };

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    if (leaf["suggestionText"] || leaf["name"]) {
      return (
        <>
          <Tooltip
            title={
              leaf["name"]
                ? `${
                    leaf["suggestionText"]
                      ? leaf["suggestionText"]
                      : leaf["role"]
                  }  - ${leaf["name"]}`
                : leaf["suggestionText"]
            }
            placement="top"
          >
            <span
              className={"timecode text"}
              style={{
                borderRadius: "5px",
                backgroundColor: leaf["color"],
              }}
              data-start={children.props.parent.start}
              data-previous-timings={children.props.parent.previousTimings}
              {...attributes}
            >
              {children}{" "}
            </span>
          </Tooltip>
          {leaf["suggestionText"] && (
            <>
              <ActionButton
                contentEditable={false}
                onClick={() =>
                  handleChange(children.props.leaf, children.props.parent)
                }
              >
                <img
                  style={{ margin: "-4px" }}
                  src={require("../../../assets/images/checkBoxSelected.svg")}
                  alt="submit"
                />
              </ActionButton>
              <ActionButton
                style={{ marginLeft: "2px" }}
                contentEditable={false}
                onClick={() =>
                  handleRemove(children.props.leaf, children.props.parent)
                }
              >
                <img
                  style={{ margin: "-4px" }}
                  src={require("../../../assets/images/closeButton.svg")}
                  alt="submit"
                />
              </ActionButton>
            </>
          )}
        </>
      );
    }
    return (
      <span
        className={"timecode text"}
        style={{
          borderRadius: "5px",
          backgroundColor: leaf["color"],
        }}
        data-start={children.props.parent.start}
        data-previous-timings={children.props.parent.previousTimings}
        {...attributes}
      >
        {children}
      </span>
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleOnKeyDown = (event) => {
  //   if (event.altKey && event.key === "s") {
  //     updateState(data);
  //   } else if (event.key === "Backspace") {
  //     const { selection } = editor;
  //     const { focus } = selection;
  //     const { offset } = focus;
  //     if (focus && offset === 0 && focus.path.length > 1) {
  //       const prevPath = focus.path.slice(0, focus.path.length - 1);
  //       const prevNode = Editor.node(editor, prevPath)[0];
  //       const addNode = Editor.node(editor, [prevPath - 1])[0];
  //       Transforms.setNodes(
  //         editor,
  //         {
  //           type: "timedText",
  //           mediaId: addNode?.mediaId + "," + prevNode?.mediaId,
  //           end: prevNode?.end,
  //         },
  //         { at: [prevPath - 1] }
  //       );
  //       // const firstBlock = Editor.node(editor, [0])[1];
  //       // if (!firstBlock) {
  //       //   const prevPath = focus.path.slice(0, focus.path.length - 1);
  //       //   const prevNode = Editor.node(editor, prevPath)[0];
  //       //   const addNode = Editor.node(editor, [prevPath - 1])[0];
  //       //   Transforms.setNodes(
  //       //     editor,
  //       //     {
  //       //       type: "timedText",
  //       //       mediaId: addNode?.mediaId + "," + prevNode?.mediaId,
  //       //       end: prevNode?.end,
  //       //     },
  //       //     { at: [prevPath - 1] }
  //       //   );
  //       // }
  //     }
  //   } else if (event.key === "Delete") {
  //     const { selection } = editor;
  //     const { focus } = selection;
  //     const { offset } = focus;
  //     const prevPath = focus.path.slice(0, focus.path.length - 1);
  //     const prevNode = Editor.node(editor, prevPath)[0];
  //     if (prevNode.children[0].text.length === offset) {
  //       const addNode = Editor.node(editor, [parseInt(prevPath) + 1])[0];
  //       Transforms.setNodes(
  //         editor,
  //         {
  //           type: "timedText",
  //           mediaId: prevNode?.mediaId + "," + addNode?.mediaId,
  //           end: prevNode?.end,
  //         },
  //         { at: [prevPath] }
  //       );
  //       // if (![parseInt(editor.children.length - 1)]) {
  //       //   const addNode = Editor.node(editor, [parseInt(prevPath) + 1])[0];
  //       //   Transforms.setNodes(
  //       //     editor,
  //       //     {
  //       //       type: "timedText",
  //       //       mediaId: prevNode?.mediaId + "," + addNode?.mediaId,
  //       //       end: prevNode?.end,
  //       //     },
  //       //     { at: [prevPath] }
  //       //   );
  //       // }
  //     }
  //   }
  // };

  // const handleOnKeyDown = (event) => {
  //   if (event.altKey && event.key === "s") {
  //     updateState(data);
  //   } else if (event.key === "Backspace") {
  //     const { selection } = editor;
  //     const { focus } = selection;
  //     const { offset } = focus;
  //     if (focus && offset === 0 && focus.path.length > 1) {
  //       const prevPath = focus.path.slice(0, focus.path.length - 1);
  //       const prevNode = Editor.node(editor, prevPath)[0];
  //       const addNode = Editor.node(editor, [prevPath - 1])[0];
  //       Transforms.setNodes(
  //         editor,
  //         {
  //           type: "timedText",
  //           mediaId: addNode?.mediaId + "," + prevNode?.mediaId,
  //           end: prevNode?.end,
  //         },
  //         { at: [prevPath - 1] }
  //       );
  //     } else {
  //       if (selection && Range.isExpanded(selection)) {
  //         const prevPath = focus.path.slice(0, focus.path.length - 1);
  //         Transforms.setNodes(editor, { at: [prevPath] });
  //       }
  //     }
  //   } else if (event.key === "Delete") {
  //     const { selection } = editor;
  //     const { focus } = selection;
  //     const { offset } = focus;
  //     const prevPath = focus.path.slice(0, focus.path.length - 1);
  //     const prevNode = Editor.node(editor, prevPath)[0];
  //     if (prevNode.children[0].text.length === offset) {
  //       const addNode = Editor.node(editor, [parseInt(prevPath) + 1])[0];
  //       const [start] = Range.edges(selection);
  //       const pointAfter = Editor.after(editor, start);
  //       const blockAfter = Editor.node(editor, [pointAfter.path[0]])[0];
  //       Transforms.setNodes(
  //         editor,
  //         {
  //           type: "timedText",
  //           mediaId: prevNode?.mediaId + "," + addNode?.mediaId,
  //           end: blockAfter?.end,
  //         },
  //         { at: [prevPath] }
  //       );
  //     } else {
  //       if (selection && Range.isExpanded(selection)) {
  //         const prevPath = focus.path.slice(0, focus.path.length - 1);
  //         Transforms.setNodes(editor, { at: [prevPath] });
  //       }
  //     }
  //   } else if (event.key === "Enter") {
  //     const { selection } = editor;
  //     const { focus } = selection;
  //     if (selection && Range.isExpanded(selection)) {
  //       const prevPath = focus.path.slice(0, focus.path.length - 1);
  //       Transforms.setNodes(editor, { at: [prevPath] });
  //     } else {
  //       const [start] = Range.edges(selection);

  //       const pointBefore = Editor.before(editor, start);
  //       const nodeBefore = Editor.node(editor, pointBefore)[0];
  //       const splitTxet = nodeBefore.text.split(" ");
  //       const textLength = splitTxet.length;
  //       const nextPath = [pointBefore.path[0] + 1];
  //       const timeStampCheck = [pointBefore.path[0] + 2];
  //       setTrigger({
  //         curretNode: [pointBefore.path[0]],
  //         length: textLength,
  //         nextNode: nextPath,
  //         timeCheck: timeStampCheck,
  //       });
  //     }
  //   }
  // };

  const handleOnKeyDown = (event) => {
    const { selection } = editor;
    const lastNode = Editor.end(editor, []);
    if (selection && Range.isCollapsed(selection)) {
      // Get the current path and the last node's path

      // Check if the current path is the last node
      if (
        lastNode.path[0] === selection.anchor.path[0] &&
        LiveTranscription &&
        event.key !== "ArrowUp"
      ) {
        event.preventDefault();
      }
    }
    if (event.altKey && event.key === "s") {
      updateState(data);
    } else if (
      (lastNode.path[0] === selection.anchor.path[0]) !== true &&
      event.key === "Backspace"
    ) {
      const { selection } = editor;
      const { focus } = selection;
      const { offset } = focus;
      if (focus && offset === 0 && focus.path.length > 1) {
        const prevPath = focus.path.slice(0, focus.path.length - 1);
        const prevNode = Editor.node(editor, prevPath)[0];
        const addNode = Editor.node(editor, [prevPath - 1])[0];
        Transforms.setNodes(
          editor,
          {
            type: "timedText",
            mediaId: addNode?.mediaId + "," + prevNode?.mediaId,
            end: prevNode?.end,
          },
          { at: [prevPath - 1] },
        );
      } else {
        if (selection && Range.isExpanded(selection)) {
          const [start, end] = Range.edges(selection);
          const range = { anchor: start, focus: end };
          Transforms.delete(editor, { at: range });
          const [node, nodePath] = Editor.node(editor, range.anchor.path);
          const maxOffset = node.text.length; // Maximum offset for the node

          // Validate the offset and set it to a valid value if out of bounds
          const offset = Math.min(range.anchor.offset, maxOffset);
          const adjustedSelection = {
            anchor: { path: nodePath, offset },
            focus: { path: nodePath, offset },
          };
          editor.selection = null;
          setCursor({
            selection: adjustedSelection,
          });
        }
      }
    } else if (
      (lastNode.path[0] === selection.anchor.path[0]) !== true &&
      event.key === "Delete"
    ) {
      if (lastNode.path[0] - 1 === selection.anchor.path[0]) {
        const [node] = Editor.node(editor, selection);
        if (
          node?.text.length === selection.anchor.offset &&
          LiveTranscription &&
          event.key !== "ArrowUp"
        ) {
          event.preventDefault();
        }
      } else {
        const { selection } = editor;
        const { focus } = selection;
        const { offset } = focus;
        const prevPath = focus.path.slice(0, focus.path.length - 1);
        const prevNode = Editor.node(editor, prevPath)[0];
        if (prevNode.children[0].text.length === offset) {
          if (selection && Range.isExpanded(selection)) {
            const [start, end] = Range.edges(selection);
            const range = { anchor: start, focus: end };
            Transforms.delete(editor, { at: range });
            const [node, nodePath] = Editor.node(editor, range.anchor.path);
            const maxOffset = node.text.length; // Maximum offset for the node

            // Validate the offset and set it to a valid value if out of bounds
            const offset = Math.min(range.anchor.offset, maxOffset);
            const adjustedSelection = {
              anchor: { path: nodePath, offset },
              focus: { path: nodePath, offset },
            };
            editor.selection = null;
            setCursor({
              selection: adjustedSelection,
            });
          } else {
            const addNode = Editor.node(editor, [parseInt(prevPath) + 1])[0];
            const [start] = Range.edges(selection);
            const pointAfter = Editor.after(editor, start);
            const blockAfter = Editor.node(editor, [pointAfter.path[0]])[0];
            Transforms.setNodes(
              editor,
              {
                type: "timedText",
                mediaId: prevNode?.mediaId + "," + addNode?.mediaId,
                end: blockAfter?.end,
              },
              { at: [prevPath] },
            );
          }
        } else {
          if (selection && Range.isExpanded(selection)) {
            const [start, end] = Range.edges(selection);
            const range = { anchor: start, focus: end };
            Transforms.delete(editor, { at: range });
            const [node, nodePath] = Editor.node(editor, range.anchor.path);
            const maxOffset = node.text.length; // Maximum offset for the node

            // Validate the offset and set it to a valid value if out of bounds
            const offset = Math.min(range.anchor.offset, maxOffset);
            const adjustedSelection = {
              anchor: { path: nodePath, offset },
              focus: { path: nodePath, offset },
            };
            editor.selection = null;
            setCursor({
              selection: adjustedSelection,
            });
          }
        }
      }
    } else if (event.key === "Enter") {
      const { selection } = editor;
      if (selection && Range.isExpanded(selection)) {
        event.preventDefault();
        // const [start, end] = Range.edges(selection);
        // const range = { anchor: start, focus: end };
        // // Transforms.delete(editor, { at: range });
        // const [node, nodePath] = Editor.node(editor, range.anchor.path);
        // const maxOffset = node.text.length; // Maximum offset for the node

        // // Validate the offset and set it to a valid value if out of bounds
        // const offset = Math.min(range.anchor.offset, maxOffset);
        // const adjustedSelection = {
        //   anchor: { path: nodePath, offset },
        //   focus: { path: nodePath, offset },
        // };
        // editor.selection = null;
        // setCursor({
        //   selection: adjustedSelection,
        // });
      } else {
        const selected = Editor.last(editor, editor.selection);

        if (selected[0]?.color) {
          event.preventDefault();
        }
        const [start] = Range.edges(selection);

        const pointBefore = Editor.before(editor, start);
        const nodeBefore = Editor.node(editor, pointBefore)[0];
        const splitTxet = nodeBefore.text.split(" ");
        const textLength = splitTxet.length;
        const nextPath = [pointBefore.path[0] + 1];
        const timeStampCheck = [pointBefore.path[0] + 2];
        setTrigger({
          curretNode: [pointBefore.path[0]],
          length: textLength,
          nextNode: nextPath,
          timeCheck: timeStampCheck,
        });
      }
    } else if (event.altKey && event.key === "t") {
      const { selection } = editor;
      const [startNode] = Editor.path(editor, selection.anchor);
      const currentNode = Editor.node(editor, [startNode])[0];
      setState({
        state: true,
        element: currentNode,
        selection: editor.selection,
      });
    }
  };

  useEffect(() => {
    if (cursor?.selection) {
      editor.selection = cursor.selection;
      setCursor({});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (trigger.curretNode) {
      const previousNode = value[trigger.curretNode];
      const previousTextLength = previousNode.children[0].text
        .split(" ")
        .filter((word) => word !== "");

      /* old code

      let timeInt =
        Math.ceil(
          (previousTextLength.length / trigger.length) *
            (previousNode.end - previousNode.start)
        ) + previousNode.start;
      let nextStop = value[trigger.timeCheck]
        ? value[trigger.timeCheck].start
        : "";

      if (nextStop && nextStop < timeInt) timeInt = nextStop - 1;
      Transforms.setNodes(
        editor,
        { type: "timedText", end: timeInt },
        { at: trigger.curretNode }
      );
      Transforms.setNodes(
        editor,
        { type: "timedText", start: timeInt },
        { at: trigger.nextNode }
      );

      */

      let timeInt =
        Math.ceil(
          (previousTextLength.length / trigger.length) *
            (previousNode.end - previousNode.start),
        ) + previousNode.start;
      let timeIntEnd = timeInt;
      let timeIntStart = timeInt;
      let nextStop = value[trigger.timeCheck]
        ? value[trigger.timeCheck].start
        : "";
      if (nextStop !== previousNode.start && nextStop < timeInt) {
        timeIntStart = nextStop - 1;
        timeIntEnd = nextStop - 1;
      } else if (nextStop === previousNode.start && nextStop < timeInt) {
        timeIntStart = previousNode.start;
        timeIntEnd = previousNode.end;
      }

      if (nextStop) {
        Transforms.setNodes(
          editor,
          { type: "timedText", end: timeIntEnd },
          { at: trigger.curretNode },
        );
        Transforms.setNodes(
          editor,
          { type: "timedText", start: timeIntStart },
          { at: trigger.nextNode },
        );
      }

      setTrigger({});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleTimeClick = () => {
    editor.selection = null;
  };

  const highlightText = (currentTime) => {
    let boolean;
    if (currentTime) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].start <= currentTime && value[i].end >= currentTime) {
          boolean = `${value[i].start} ${value[i].end}`;
          break;
        }
      }
      return boolean;
    }
  };

  /* Highlight 

  const handleSelectionChange = () => {
    const { selection } = editor;
    const captureText = window.getSelection().toString();
    if (selection && Range.isExpanded(selection)) {
      const [start, end] = Range.edges(selection);
      const [startNode] = Editor.path(editor, selection.anchor);
      const currentNode = Editor.node(editor, [startNode])[0];
      let highlightedText = [];
      let currentIndex = 0;
      const ranges = [{ start: start?.offset, end: end?.offset }];
      if (currentNode.children.length > 1) {
        const startPath = Editor.path(editor, start);
        const currentIndex = startPath[1];
        if (currentIndex !== -1) {
          const newArray =
            currentNode.children[currentIndex].text.split(captureText);
          const newChildren = newArray.reduce((acc, part, index) => {
            if (index !== newArray.length - 1) {
              acc.push({ text: part });
              acc.push({
                text: captureText,
                color: "rgba(89, 179, 48, 0.48)",
                name: name,
                role: role,
              });
            } else {
              acc.push({ text: part });
            }
            return acc;
          }, []);
          const sourceArrayCopy = [...currentNode.children];
          sourceArrayCopy[currentIndex] = newChildren;
          const updatedNode = {
            ...currentNode,
            children: sourceArrayCopy.flat(),
          };
          Transforms.removeNodes(editor, { updatedNode }, { at: [startNode] });
          Transforms.insertNodes(editor, updatedNode, { at: [startNode] });
          if (Range.isExpanded(selection)) {
            editor.selection = null;
          }
        }
      } else {
        ranges.forEach((range) => {
          const { start, end } = range;
          highlightedText.push({
            text: currentNode?.children[0].text.substring(currentIndex, start),
          });
          highlightedText.push({
            text: currentNode?.children[0].text.substring(start, end),
            color: "rgba(89, 179, 48, 0.48)",
            name: name,
            role: role,
          });
          currentIndex = end;
        });
        highlightedText.push({
          text: currentNode?.children[0].text.substring(currentIndex),
        });
        const updatedNode = { ...currentNode, children: highlightedText };
        Transforms.removeNodes(editor, { updatedNode }, { at: [startNode] });
        Transforms.insertNodes(editor, updatedNode, { at: [startNode] });
        if (Range.isExpanded(selection)) {
          editor.selection = null;
        }
      }
    } else {
      if (selection?.anchor && selection?.focus) {
        const [start, end] = Range.edges(selection);
        const range = { anchor: start, focus: end };
        const [node, nodePath] = Editor.node(editor, range.anchor.path);
        const maxOffset = node.text.length; // Maximum offset for the node

        // Validate the offset and set it to a valid value if out of bounds
        const offset = Math.min(range.anchor.offset, maxOffset);

        if (node.color && maxOffset === offset) {
          // Transforms.delete(editor, { at: range });

          const adjustedSelection = {
            anchor: { path: nodePath, offset },
            focus: { path: nodePath, offset },
          };
          editor.selection = adjustedSelection;
        }
      }
    }
  };

  useEffect(() => {
    if (captureText) {
      // Attach the event listener when the component mounts
      // document.addEventListener("mouseup", handleSelectionChange);
      return () => {
        // document.removeEventListener("mouseup", handleSelectionChange);
      };
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  */

  const handleSelectionChange = () => {
    const { selection } = editor;
    if (selection?.anchor && selection?.focus) {
      const [start, end] = Range.edges(selection);
      const range = { anchor: start, focus: end };
      const [node, nodePath] = Editor.node(editor, range.anchor.path);
      const maxOffset = node.text.length; // Maximum offset for the node
      // Validate the offset and set it to a valid value if out of bounds
      const offset = Math.min(range.anchor.offset, maxOffset);

      if (node.color && maxOffset === offset) {
        // Transforms.delete(editor, { at: range });
        const adjustedSelection = {
          anchor: { path: nodePath, offset },
          focus: { path: nodePath, offset },
        };
        editor.selection = adjustedSelection;
      }
    }
  };

  useEffect(() => {
    if (captureText) {
      // Attach the event listener when the component mounts
      document.addEventListener("mouseup", handleSelectionChange);
      return () => {
        document.removeEventListener("mouseup", handleSelectionChange);
      };
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HoveringToolbar

  const HoveringToolbar = () => {
    const ref = useRef(null);
    const editor = useSlate();
    const inFocus = useFocused();

    useEffect(() => {
      const el = ref.current;
      const { selection } = editor;

      if (!el) {
        return;
      }

      if (
        !selection ||
        !inFocus ||
        Range.isCollapsed(selection) ||
        Editor.string(editor, selection) === ""
      ) {
        el.removeAttribute("style");
        return;
      }

      const domSelection = window.getSelection();
      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      el.style.opacity = "1";
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
      el.style.left = `${
        rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
      }px`;
    });

    return (
      <Portal>
        <Menu
          ref={ref}
          className={css`
            padding: 8px 7px 6px;
            position: absolute;
            z-index: 1;
            top: -10000px;
            left: -10000px;
            margin-top: -6px;
            opacity: 0;
            background-color: #222;
            border-radius: 4px;
            transition: opacity 0.75s;
          `}
          onMouseDown={(e) => {
            // prevent toolbar from taking focus away from editor
            e.preventDefault();
          }}
        >
          <FormatButton
            format="color"
            icon="highlight"
            colorCode="rgba(89, 179, 48, 0.48)"
          />
          <FormatButton format="copy" icon="content_copy" />
        </Menu>
      </Portal>
    );
  };

  const CopytoClipboard = () => {
    // Set the value of the textarea to the text you want to copy
    navigator.clipboard.writeText(window.getSelection().toString());
  };

  const FormatButton = ({ format, icon, colorCode }) => {
    const editor = useSlate();
    return format === "copy" ? (
      <CopyButton reversed onClick={CopytoClipboard}>
        <Icon>{icon}</Icon>
      </CopyButton>
    ) : (
      <Button
        reversed
        active={isMarkActive(editor, format, colorCode)}
        onClick={() => toggleMark(editor, format, colorCode)}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  };

  const toggleMark = (editor, format, colorCode) => {
    const isActive = isMarkActive(editor, format, colorCode);

    if (isActive) {
      Editor.removeMark(editor, format);
      Editor.removeMark(editor, "name");
      Editor.removeMark(editor, "role");
      editor.selection = null;
    } else {
      Editor.addMark(editor, format, colorCode);
      Editor.addMark(editor, "name", name);
      Editor.addMark(editor, "role", role);
    }
  };

  const isMarkActive = (editor, format, colorCode) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === colorCode : false;
  };

  return (
    <>
      <React.Fragment>
        <style scoped>
          {`
             .timecode[data-previous-timings*="${highlightText(currentTime)}"]{
                  color:  ${COLORS.BTN_GREEN};
              }
          `}
        </style>
        {value.length !== 0 ? (
          <Slate
            editor={editor}
            value={value}
            onChange={(value) => {
              return setValue(value);
            }}
          >
            {isPlaying?.isPlaying ? null : <HoveringToolbar />}
            <Editable
              readOnly={false}
              autoFocus
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onKeyDown={handleOnKeyDown}
              onMouseDown={handleTimeClick}
              onDOMBeforeInput={(event) => {
                switch (event.inputType) {
                  case "formatHighlight":
                    event.preventDefault();
                    return toggleMark(editor, "rgba(89, 179, 48, 0.48)");
                  default:
                    break;
                }
              }}
            />
          </Slate>
        ) : null}
      </React.Fragment>
      {state?.state ? (
        <UpdateSpeaker
          {...{ state, setState, speakersData, isEditable, editor, setCursor }}
        />
      ) : null}
    </>
  );
};

export default Index;

const Container = styled.div`
  display: flex;
`;
const DrawerContainer = styled.div`
  width: 100%;
`;

const ValueSpeaker = styled.span`
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueTimesCode = styled.span`
  width: 80px;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  &:hover {
    text-decoration: underline;
  }
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueConfidence = styled.span`
  width: 68px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-right: 10px;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueIds = styled.span`
  width: 50px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 5px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const CustomTextAreaOtter = styled.div`
  width: 85%;
  font-size: 16px;
  margin-left: 125px;
  margin-right: 25px;
  margin-bottom: 8px;
  line-height: 1.5;
  outline: none;
  border: none;
  resize: none;
  font-family: ${theme.fonts.primaryFontSemiBold};
  background-color: ${COLORS.PRIMARY_WHITE};
  @media ${theme.breakpoints.sm_down} {
    margin-left: 30px;
    font-size: 14px;
  }
`;

export const QuitContainer = styled.div`
  padding: 18px;
  margin-left: 117px;
  margin-right: 25px;
  border-radius: 7px;
  background-color: ${COLORS.LIGHT_BLUE};
  border: solid 1px ${COLORS.COLOR_DARK};
  margin-bottom: 12px;
  max-width: 80%;
`;

export const ProfileImage = styled.img`
  width: 20px;
  height: 25px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 4px;
  margin-left: 5px;
`;

export const AcceptButton = styled.button`
  border: 2px solid #00838c;
  background-color: white;
  outline: none;
  border-radius: 5px;
  border-width: 2.2px;
  border-style: solid;
  margin-left: 4px;
  height: 20px;
  cursor: pointer;
  color: #00838c;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const DeclineButton = styled.button`
  border: 2px solid #d91b1b;
  background-color: white;
  outline: none;
  border-radius: 5px;
  border-width: 2.2px;
  border-style: solid;
  margin-left: 4px;
  height: 20px;
  cursor: pointer;
  color: #d91b1b;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  // margin-left: 5px;
`;
